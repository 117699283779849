/* You can add global styles to this file, and also import other style files */
html, body {
    width:100%;
    height:100%;
    margin:0px;
    padding:0px;
    font-family: 'Montserrat', sans-serif;
}

.sidebar {
    float:left;
    border-color: #3f3f3f;
    background-color: #29292d;
    width:56px;
    height:100%;
}

    .sidebar .logo {
        float:left;
        width:56px;
        height:56px;
        background-color:black;
        background-image:url(assets/axion-ic.svg);
        background-size:30px 30px;
        background-repeat:no-repeat;
        background-position:center;
    }

    .sidebar .sidemenu {
        list-style:none;
        padding:0px;
        margin:0px;
        float:left;
        width:56px;
    }

        .sidebar .sidemenu li {
            float:left;
            width:56px;
            height:56px;
            outline:none;
            user-select:none;
            transition:all 0.3s ease;
            cursor:pointer;
        }
        .sidebar .sidemenu li:not(.active):hover { opacity:0.6; }

            .sidebar .sidemenu li .icon {
                float:left;
                width:30px;
                height:30px;
                margin-left:13px;
                margin-top:13px;
                background-size:cover;
                background-repeat:no-repeat;
                background-position: center;
            }

        .navbar {
            float:left;
            width:calc(100% - 56px);
            height:56px;
            background:#29292d;
        }

        .content {
            float:left;
            width:calc(100% - 56px);
            height:calc(100% - 56px);
            background:rgb(11, 11, 11);
            overflow-x: hidden;
            overflow-y: auto;
        }

            .content .content-title {
                float:left;
                width:100%;
                height:56px;
                line-height:56px;
                text-align:center;
                border-bottom:1px solid rgb(51, 53, 67);
                color:hsla(0,0%,100%,.65);
                user-select: none;
                font-size:14px;
                position:relative;
            }

                .content .content-title .action-left {
                    position: absolute;
                    left: 10px;
                    height: 30px;
                    width: 30px;
                    top: 13px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor:pointer;
                }

                .content .content-title .action-right {
                    position: absolute;
                    right: 10px;
                    height: 30px;
                    width: 30px;
                    top: 13px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition:all 0.3s ease;
                    cursor:pointer;
                }

                .content .content-title .action-left:hover, .content .content-title .action-right:hover { opacity:0.6; }

            .content .sub-title {
                float: left;
                width: 100%;
                height: 56px;
                color: #cccccc;
                text-align: center;
                line-height: 56px;
                border-bottom: 1px solid #1f1f1f;
                font-weight: bold;
                font-size: 14px;
                position:relative;
            }

            .content .sub-title .action-left {
                position: absolute;
                left: 10px;
                height: 30px;
                width: 30px;
                top: 13px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                cursor:pointer;
            }

            .content .sub-title .action-right {
                position: absolute;
                right: 10px;
                height: 30px;
                width: 30px;
                top: 13px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                transition:all 0.3s ease;
                cursor:pointer;
            }

            .content .sub-title .action-left:hover, .content .sub-title .action-right:hover { opacity:0.6; }

                .content .date-grid {
                    float: left;
                    width: 100%;
                    height: calc(100% - 112px);
                }

                    .content .date-grid .grid-hdr {
                        float: left;
                        width: 100%;
                    }

                        .content .date-grid .grid-hdr span {
                            float: left;
                            width: 14.28%;
                            text-align: center;
                            height: 30px;
                            line-height: 30px;
                            color: white;
                            font-size: 12px;
                            text-transform: uppercase;
                            background: rgb(33 33 33 / 40%);
                            outline:none;
                            cursor:pointer;
                        }

                    .content .date-grid .grid-body {
                        float: left;
                        width: 100%;
                        height: 100%;
                    }

                        .content .date-grid .grid-body > span {
                            float: left;
                            width: calc(14.28% - 2px);
                            height: calc(16.66% - 2px);
                            border: 1px solid #292929;
                            display: block;
                            text-align: center;
                            color: #b7b7b7;
                            transition:all 0.3s ease;
                            position:relative;
                            cursor:pointer;
                        }
                        .content .date-grid .grid-body > span.current { border-color:#969696; color:#969696; }
                        .content .date-grid .grid-body > span.disabled { opacity:0.4; cursor:not-allowed; }
                        .content .date-grid .grid-body > span:not(.disabled):hover {
                            color:#006FFF;
                            border-color:#006FFF;
                        }

                            .content .date-grid .grid-body > span span {
                                float:left;
                                width:100%;
                                text-align:center;
                                margin-top:10px;
                            }

                            .content .date-grid .grid-body .st {
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                left: 10px;
                                top: 2px;
                                background: #4dc14d;
                                border-radius: 100%;
                            }

            .content .table-wrapper {
                float:left;
                width:calc(100% - 40px);
                margin-left:20px;
                margin-top:20px;
            }

                .content .table-wrapper th {
                    text-align: center;
                    color: #b5b5b5;
                    font-size: 12px;
                    height: 30px;
                    line-height: 30px;
                    text-transform: uppercase;
                    border-bottom: 1px solid #232323;
                }

                .content .table-wrapper td {
                    text-align:center;
                    color:#b5b5b5;
                    font-size:14px;
                    height:30px;
                    line-height:30px;
                    border-bottom:1px solid #232323;
                }
            
            .content .pagination {
                float: left;
                width: calc(100% - 40px);
                margin-left: 20px;
                margin-top: 20px;
            }

                .content .pagination span {
                    float: left;
                    font-size: 12px;
                    color: #c3c3c3;
                    height:16px;
                    line-height:16px;
                }

                .content .pagination .page-control {
                    float:left;
                    margin-left:10px;
                }

                    .content .pagination .page-control .page-left {
                        float:left;
                        height:16px;
                        width:16px;
                        line-height:16px;
                        border:1px solid gray;
                        color:gray;
                        text-align:center;
                        border-top-left-radius:5px;
                        border-bottom-left-radius: 5px;
                        border-right:0px;
                        cursor:pointer;
                        user-select: none;
                    }

                    .content .pagination .page-control .page-right {
                        float:left;
                        height:16px;
                        width:16px;
                        line-height:16px;
                        border:1px solid gray;
                        color:gray;
                        text-align:center;
                        border-top-right-radius:5px;
                        border-bottom-right-radius: 5px;
                        border-left:0px;
                        cursor:pointer;
                        transition:all 0.3s ease;
                        user-select: none;
                    }

                    .content .pagination .page-control .page-left.selectable, .content .pagination .page-control .page-right.selectable {
                        color:white;
                        border-color:white;
                    }
                    .content .pagination .page-control .page-left.selectable:hover, .content .pagination .page-control .page-right.selectable:hover {
                        color:#006FFF;
                        border-color:#006FFF;
                    }
                    .content .pagination .page-control .page-left.selectable { border-right:1px solid white; }
                    .content .pagination .page-control .page-right.selectable { border-left:1px solid white; }
                    

            .content .timetable-hdr {
                float: left;
                width: 100%;
            }

                .content .timetable-hdr .primary {
                    float: left;
                    width: calc(20% - 11px);
                    height: 40px;
                    line-height: 40px;
                    border-bottom: 1px solid #252525;
                    color: white;
                    font-size: 12px;
                    font-weight: bold;
                    padding-left: 10px;
                    border-right: 1px solid #252525;
                }

                .content .timetable-hdr .hours {
                    float: left;
                    width: 80%;
                    height: 40px;
                    border-bottom: 1px solid #252525;
                }

                    .content .timetable-hdr .hours span {
                        float: left;
                        height: 40px;
                        line-height: 40px;
                        width: calc(12.5% - 7px);
                        color: white;
                        font-size: 10px;
                        padding-left: 5px;
                        border-right: 1px solid #252525;
                    }

            .content .timetable-body {
                float: left;
                width: 100%;
                height: calc(100% - 98px);
                overflow: auto;
                position:relative;
            }

                .content .timetable-body .loading {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0px;
                    top: 0px;
                    background: black;
                    z-index: 10;
                }

                    .content .timetable-body .loading span {
                        float: left;
                        width: 100%;
                        margin-top: calc(50vh - 200px);
                        color: white;
                        text-align: center;
                    }

                .content .timetable-body .timeline-row {
                    float: left;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                }

                    .content .timetable-body .timeline-row .name {
                        float: left;
                        width: calc(20% - 11px);
                        height: 40px;
                        line-height: 40px;
                        border-bottom: 1px solid #252525;
                        color: white;
                        font-size: 12px;
                        /* font-weight: bold; */
                        padding-left: 10px;
                        border-right: 1px solid #252525;
                    }

                    .content .timetable-body .timeline-row .line {
                        position:relative;
                        float: left;
                        width: 80%;
                        height: 40px;
                        border-bottom: 1px solid #252525;
                    }

                        .content .timetable-body .timeline-row .line .fill {
                            position:absolute;
                            float: left;
                            height: 38px;
                            background: #4dc14d;
                            border-left: 1px solid white;
                            border-right: 1px solid white;
                            width: 200px;
                            cursor:pointer;
                            transition:all 0.3s ease;
                            margin-top:1px;
                        }
                        .content .timetable-body .timeline-row .line .fill:hover { background: rgb(79 255 79 / 40%); }

                            .content .timetable-body .timeline-row .line .fill:hover span { opacity:1; }
                            .content .timetable-body .timeline-row .line .fill span {
                                position: absolute;
                                top: 40px;
                                background: rgb(255 255 255 / 20%);
                                z-index: 10;
                                opacity: 0;
                                color: white;
                                font-size: 12px;
                                padding-left: 10px;
                                padding-right: 10px;
                                left: calc(50% - 50px);
                                border-radius: 15px;
                                width:100px;
                                text-align:center;
                                pointer-events:none;
                            }

    .login-form {
        float: left;
        width: 300px;
        height: 300px;
        margin-left: calc(50% - 150px);
        margin-top: calc(50vh - 150px);
    }

        .login-form .input-row {
            float: left;
            width: 100%;
        }
        .login-form .input-row:nth-child(2) { margin-top:20px; }

            .login-form .input-row .label {
                float: left;
                width: 100%;
                font-size: 12px;
                color: #b9b9b9;
            }

            .login-form .input-row input {
                float: left;
                width: calc(100% - 10px);
                margin-top: 5px;
                height: 26px;
                background: transparent;
                border: 1px solid gray;
                border-radius: 5px;
                color: white;
                padding-left: 10px;
            }

        .login-form .btn {
            float: left;
            width: 100%;
            height: 30px;
            line-height: 30px;
            margin-top: 30px;
            text-align: center;
            color: white;
            text-transform: uppercase;
            font-size: 12px;
            background: #006FFF;
            border-radius: 5px;
            cursor: pointer;
            user-select: none;
            transition:all 0.3s ease;
        }
        .login-form .btn:hover { background:#0f78ff; }

.badge {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 8px;
    color:white;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: bold;
}
.badge.positive {
    background: rgb(80 181 97 / 70%);
    border: 1px solid rgb(80 181 97 / 70%);
}
.badge.inactive {
    background: rgb(103 103 103 / 70%);
    border: 1px solid rgb(103 103 103 / 100%);
}
.icon { outline:none; }
.icon.staff { background-image:url(assets/staff-icon.svg); }
li.active > .icon.staff { background-image:url(assets/staff-icon-active.svg); }
.icon.device { background-image:url(assets/device-icon.svg); }
li.active > .icon.device { background-image:url(assets/device-icon-active.svg); }
.icon.calendar { background-image:url(assets/calendar-icon.svg); }
li.active > .icon.calendar { background-image:url(assets/calendar-icon-active.svg); }
.icon.calendar-staff { background-image:url(assets/staff-calendar-icon.svg); }
.icon.calendar-staff:hover { background-image:url(assets/staff-calendar-icon-active.svg); }
.icon.view-photo { background-image:url(assets/view-photo.svg); }
.icon.view-photo:hover { background-image:url(assets/view-photo-active.svg); }

.icon.arrow-back { background-image:url(assets/arrow-back-icon.svg); }
.icon.arrow-left { background-image:url(assets/left-arrow-icon.svg); }
.icon.arrow-right { background-image:url(assets/right-arrow-icon.svg); }
/* #006FFF */

.action {
    float: left;
    width: 30px;
    height: 30px;
}

    .action .icon {
        height: 16px;
        float: left;
        width: 16px;
        background-size: cover;
        margin-top: 8px;
        transition:all 0.3s ease;
    }

.hidden { display:none; }